import React from 'react';
import img1 from '../../assets/img/gre1.svg';
import img2 from '../../assets/img/gre2.svg';
import img3 from '../../assets/img/gre3.svg';



const RoadMap = () => {
  return (
    <>
        <div className='row my-5'>
            <figcaption figcaption class="blockquote-footer mt-5 text-white mb-0 pb-0">
                ROADMAPS
            </figcaption>
            <h1 className='theme-color font-family  mb-3' style={{fontSize: '50px'}}>RoadMap</h1>
            <div className='col-md-4 d-flex justify-content-center'>
                <div className='d-flex align-items-center'>
                    <img src={img1} width='50'/>
                    <div className='ms-3'>
                        <h4 className='theme-color font-family'>Phase 1</h4>
                        <p className='text-white py-0 my-0'>Conceptualize Platform</p>
                        <p className='text-white py-0 my-0'>Build Team</p>
                        <p className='text-white py-0 my-0'>Launch Website</p>
                        <p className='text-white py-0 my-0'>Release White Paper</p>
                    </div>
                </div>
            </div>
            <div className='col-md-4 d-flex justify-content-center'>
                <div className='d-flex align-items-center'>
                    <img src={img2} width='50'/>
                    <div className='ms-3'>
                        <h4 className='theme-color font-family'>Phase 2</h4>
                        <p className='text-white py-0 my-0'>Form Partnerships</p>
                        <p className='text-white py-0 my-0'>Expand Community</p>
                        <p className='text-white py-0 my-0'>Conduct First Audit</p>
                        <p className='text-white py-0 my-0'>Start Marketing</p>
                    </div>
                </div>
            </div>
            <div className='col-md-4 d-flex justify-content-center'>
                <div className='d-flex align-items-center'>
                    <img src={img3} width='50'/>
                    <div className='ms-3'>
                        <h4 className='theme-color font-family'>Phase 3</h4>
                        <p className='text-white py-0 my-0'>Implement Tier System</p>
                        <p className='text-white py-0 my-0'>List IDO Projects</p>
                        <p className='text-white py-0 my-0'>Complete KYC</p>
                        <p className='text-white py-0 my-0'>List on CMC & CoinGecko</p>
                    </div>
                </div>
            </div>
            <div className='col-md-4 d-flex justify-content-center'>
                <div className='d-flex align-items-center'>
                    <img src={img1} width='50'/>
                    <div className='ms-3'>
                        <h4 className='theme-color font-family'>Phase 4</h4>
                        <p className='text-white py-0 my-0'>Secure Exchange Listing</p>
                        <p className='text-white py-0 my-0'>Enhance Security</p>
                        <p className='text-white py-0 my-0'>Upgrade UI</p>
                        <p className='text-white py-0 my-0'>Engage Community</p>
                    </div>
                </div>
            </div>
            <div className='col-md-4 d-flex justify-content-center'>
                <div className='d-flex align-items-center'>
                    <img src={img2} width='50'/>
                    <div className='ms-3'>
                        <h4 className='theme-color font-family'>Phase 5</h4>
                        <p className='text-white py-0 my-0'>Develop Advanced Features</p>
                        <p className='text-white py-0 my-0'>Explore New Technologies</p>
                        <p className='text-white py-0 my-0'>Incorporate Feedback</p>
                        <p className='text-white py-0 my-0'>Expand Market Reach</p>
                    </div>
                </div>
            </div>
            <div className='col-md-4 d-flex justify-content-center'>
                <div className='d-flex align-items-center'>
                    <img src={img3} width='50'/>
                    <div className='ms-3'>
                        <h4 className='theme-color font-family'>Phase 6</h4>
                        <p className='text-white py-0 my-0'>Global Expansion</p>
                        <p className='text-white py-0 my-0'>Forge New Partnerships</p>
                        <p className='text-white py-0 my-0'>Enhance Scalability</p>
                        <p className='text-white py-0 my-0'>Drive Innovation</p>
                    </div>
                </div>
            </div>
        </div> 
    </>
  );
}


export default RoadMap;
