import React from 'react';
import { Link } from 'react-router-dom';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import logo from '../../assets/img/logo.png'
import { HashLink } from 'react-router-hash-link';

const Navbar = () => {
  window.addEventListener('scroll', function() {
    const scrollValue = window.scrollY;

    if (scrollValue > 80) {
      let navbar = document.getElementById('navbar');
      navbar.classList.add('back-blur', 'sticky-top', 'animate');
    } else {
      let navbar = document.getElementById('navbar');
      navbar.classList.remove('back-blur', 'sticky-top');
      navbar.classList.add('animate');
    }
  });
  return (
    <>
      <nav id='navbar' className="navbar py-3 navbar-expand-lg navbar-dark bg-dark">
        <div className="container-fluid">
          <Link className="navbar-brand fs-3" to="/">
<img src={logo} />
        </Link>
          <div className='connect-wallet-mbl-view d-none'>
            <ConnectButton chainStatus="icon" accountStatus="address" label="Connect" showBalance={false} className="btn btn-primary rounded-pill px-4"/>
          </div>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item ms-4">
                <Link className="nav-link" aria-current="page" to="/">Home</Link>
              </li>
              <li className="nav-item ms-4">
                <Link className="nav-link" to="/about">About</Link>
              </li>
              <li className="nav-item ms-4">
                <HashLink className="nav-link" to="/#roadmap">RoadMap</HashLink>
              </li>
              <li className="nav-item ms-4">

                <HashLink className="nav-link" to="/#tiers">Tiers</HashLink>
              </li>
              <li className="nav-item ms-4">
                <HashLink className="nav-link" to="/#partners">Partners</HashLink>
              </li>
             
             {/*
              <li className="nav-item dropdown ms-4">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Others Page
                </a>
                <ul className="dropdown-menu mt-3 px-2" aria-labelledby="navbarDropdown">
                  <li><Link className="nav-link" to="/farming">Farming</Link></li>
                  <li><Link className="nav-link" to="/apply_projects">Apply</Link></li>
                  <li><Link className="nav-link" to="/calender">Calender</Link></li>
                  <li><Link className="nav-link" to="/claim">Claim</Link></li>
                  <li><Link className="nav-link" to="/staking">Stake</Link></li>
                  <li><hr className=" bg-white" /></li>
                  <li><Link className="nav-link" to="/Kyc">KYC</Link></li>
                </ul>
              </li>
*/}

            </ul>
            <form className="d-flex">
              <ConnectButton chainStatus="icon" accountStatus="address" label="Connect" showBalance={false} className="btn btn-primary rounded-pill px-4"/>
            </form>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
