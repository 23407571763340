import Web3 from 'web3';

const REACT_APP_NETWORK_URL = process.env.REACT_APP_NETWORK_URL;

if (!REACT_APP_NETWORK_URL) {
  throw new Error('REACT_APP_NETWORK_URL is not defined in the environment variables');
}

const web3 = new Web3(new Web3.providers.HttpProvider(REACT_APP_NETWORK_URL));

export default web3;
