import React from 'react';
import rocket_img from '../../assets/img/about1.png';

const About_Banner = () => {
  return (
    <>
        <div className='about-banner m-4'>
            <div className='container'>
                <div className='shadow-round rounded-circle' style={{top: '650px'}}></div>
                <div className='shadow-round rounded-circle' style={{top: '250px', right: '50px'}}></div>
                <div className='row'>
                    
                    <div className='col-lg-6 my-3  p-0 d-flex align-items-center'>
                        <div>
                            <figcaption class="blockquote-footer theme-color">
                                About Us
                            </figcaption>
                            <h1 className='p-0 m-0 main-text font-family theme-color'>
    Raise Capital For <br /> Your Project <span className='text-white'>through <br/>Blockchain</span>
</h1>
<p className='p-0 m-0 text-white lead mt-3 mb-1'>
    Welcome to FluxPad, where we revolutionize the way you fund and launch your blockchain projects. We specialize in providing cutting-edge solutions and expert support to ensure your Web 3.0 initiatives achieve their full potential. Explore our platform and discover how we can help you navigate the decentralized landscape with confidence.
</p>
                      </div>
                    </div>
                    <div className='col-lg-6 my-3 text-center'>
                        <img src={rocket_img} width={'100%'}/>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}

export default About_Banner;
