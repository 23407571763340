import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import '@rainbow-me/rainbowkit/styles.css';
import App from './App';
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
//import {arbitrum,bscTestnet,mainnet,optimism,polygon,sepolia,} from 'wagmi/chains';









import {
  avalancheFuji,
  baseGoerli,
  bscTestnet,
  goerli,
  optimismGoerli,
  polygonMumbai,
} from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";





import reportWebVitals from './reportWebVitals';



import AvaxLogo from "./assets/img/avax.svg";
import BscLogo from "./assets/img//bnb.svg";
const avalancheChain = {
  id: 43_114,
  name: "Avalanche",
  network: "avalanche",
  iconUrl: AvaxLogo,
  iconBackground: "#fff",
  nativeCurrency: {
    decimals: 18,
    name: "Avalanche",
    symbol: "AVAX",
  },
  rpcUrls: {
    default: "https://api.avax.network/ext/bc/C/rpc",
  },
  blockExplorers: {
    default: { name: "SnowTrace", url: "https://snowtrace.io" },
    etherscan: { name: "SnowTrace", url: "https://snowtrace.io" },
  },
  testnet: false,
};

const bscChain = {
  id: 56,
  name: "Binance Smart Chain",
  network: "bsc",
  iconUrl: BscLogo,
  iconBackground: "#fff",
  nativeCurrency: {
    decimals: 18,
    name: "Binance Coin",
    symbol: "BNB",
  },
  rpcUrls: {
    default: "https://bsc-dataseed.binance.org/",
  },
  blockExplorers: {
    default: { name: "BscScan", url: "https://bscscan.com" },
  },
  testnet: false,
};


const { chains, publicClient } = configureChains(
  [
    avalancheFuji,
    baseGoerli,
    bscTestnet,
    goerli,
    optimismGoerli,
    polygonMumbai,
  ],
  [publicProvider()]
);

const { connectors } = getDefaultWallets({
  appName: "My RainbowKit App",
  projectId: "573d60ae210e8d772ee485c4483cff78",
  chains,
});

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});






const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>

<WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider chains={chains}>
      <App />
      </RainbowKitProvider>
    </WagmiConfig>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
