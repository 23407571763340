import React, { useState,useEffect, Fragment } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Navbar from '../global-components/Navbar'
import Footer from '../global-components/Footer'
import img10 from '../../assets/img/com_logo.png';
import partner from '../../assets/img/part1.png';


import web3 from "../../utils/web3";
import { createWatcher } from '@makerdao/multicall';
import {useContractReader,useContractWriter} from './../../hooks/useContractAction';
import useWeb3DataReader from './../../hooks/useWeb3DataReader';
import {calculateTokens} from './../../utils/tokenCalculator'


import {useAccount,useConnect,useContract,useContractRead,useContractWrite,useNetwork,useWaitForTransaction,useBalance } from "wagmi";

import { ethers } from "ethers";
import saleAbi from "../../contracts/sale.json";
const REACT_APP_MULTI_CALL_ADDRESS = process.env.REACT_APP_MULTI_CALL_ADDRESS

const defaultWatcher = createWatcher([], {});




const PoolDetail = () => {
    const { id } = useParams();
 
    const navigate = useNavigate();
  
    const CONTRACT_ADDRESS = "0xAf806b673394D624fB534A83A672A569434a2900";
    const { address } = useAccount();
    const [mybalance, setMybalance] = useState(0);
    const [myallowance, setMyallowance] = useState(0);
    const [myDeposited, setMyDeposited] = useState(0);
    const [buyLoading, setBuyLoading] = useState(false);
    const [approved, setApproved] = useState(true);
    const [pool, setPool] = useState(null);
  
    const [raised, setRaisedAmount] = useState(0);
  
    const [amount, setAmount] = useState(100);
  
  
  
  /*
    useEffect(() => {
      axios.get('http://prismpad.io/api.php')
          .then(response => {
              const pools = response.data;
              const selectedPool = pools.find(pool => pool.params === id);
              if (selectedPool) {
                setPool(selectedPool);
            } else {
                navigate('/');
            }
          })
          .catch(error => {
              console.error('Error fetching data:', error);
          });
  }, [id,navigate]);
  */
  
  
  

/*
const { data, error, loading } = useContractReader(
    '0xYourContractAddress',
    YourContractABI,
    'yourFunctionName',
    [arg1, arg2]
);



const { txnData, txnError, txnLoading, callContract, waitData, waitError, waitLoading } = useContractWriter(
    '0xYourContractAddress',
    YourContractABI,
    'yourFunctionName',
    {
        args: [arg1, arg2],
        value: ethers.utils.parseEther('1.0'), // Optional: if sending ETH
    }
);

// To trigger the write
callContract?.();

*/


  
  const { data, isLoading, isSuccess, write } = useContractWrite({
    addressOrName: '0xC6729bfCc82A1C9dD76524bf072e94E31917A57B',
    contractInterface: saleAbi},
  'buyToken',
    {args: [web3.utils.toWei(amount.toString(), 'ether')], overrides:{
      //gasPrice:10000000
      gasLimit: 250000
    }}
  )
    useEffect(() => {
     // if(pool && !pool?.saleContract) return;
      const multiCallConfig = {
      web3,
      multicallAddress: REACT_APP_MULTI_CALL_ADDRESS,
      interval: 10000
       };
       defaultWatcher.stop();
      
       defaultWatcher.recreate(
      [
        {
       target: CONTRACT_ADDRESS,
       call: ['totalRaise()(uint256)'],
       returns: [['totalRaise', (val) => val.toString()]]
        }
      ],
      multiCallConfig
       );
      defaultWatcher.subscribe((update) => {
      setRaisedAmount(web3.utils.fromWei(update.value, 'ether'));
      });
       defaultWatcher.start();
        }, [web3]);
  
  
  
  
  
    const mintFreeTokens = async () => { 
      if (write){
        write(/*{args: [web3.utils.toWei(amount.toString(), 'ether')]}*/);
       
      }
    }
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
    const mintFreeTokensxx = async () => {
     // await buy({args: [web3.utils.toWei(amount.toString(), 'ether')]})
    }
    
  /*
  // Check TX for mint function
     const { isSuccess: txSuccess, error: txError } = useWaitForTransaction({
      confirmations: 1,
      hash: mintData?.hash,
    });
  
  */
  
  
  /*
    const { data: totalRaise } = useContractRead({
      addressOrName: CONTRACT_ADDRESS,
      contractInterface: saleAbi,},
      "totalRaise",
    );
  
    useEffect(() => {
    if (totalRaise) {
     setRaisedAmount(web3.utils.fromWei(totalRaise.toString(), 'ether'));
    }
  }, [totalRaise]);
  */
  
  
    const [activeTab, setActiveTab] = useState(1);
  
  
  
    const { data:datatotalRaise, loading: isLoadingtotalRaise, error: errortotalRaise } = useWeb3DataReader(pool?.poolContract,saleAbi,'totalRaise');
    useEffect(() => {
      if (datatotalRaise) {
       setRaisedAmount(web3.utils.fromWei(datatotalRaise.toString(), 'ether'));
      }
    }, [datatotalRaise]);
  
  
  
  const { data:getUserContribution, loading: isLoadinggetUserContribution, error: errorgetUserContribution } = useContractReader(pool?.poolContract,saleAbi,'getUserContribution')
  useEffect(() => {
    if (getUserContribution) {
      console.log(getUserContribution,'getUserContribution');
      setMyDeposited(web3.utils.fromWei(getUserContribution.toString(), 'ether'));
    }
  }, [address,getUserContribution]);
  




  
  
  /*
  
  const { allowance, isLoading: isLoadingAllowance, error: allowanceError } = useAllowance(address,pool?.saleContract);
   // const { claimable,isLoading:isLoadingclaim,error:claimError, } = useAllocation(pool?.claimContract);
  
    
    const { totalRaise, isLoadingtotalRaise, errortotalRaise } = useSaleRaised(pool?.saleContract);
    const { getUserContribution, isLoadinggetUserContribution, errorgetUserContribution } = useMyDeposited(pool?.saleContract,address);
  
    const [cap, setCap] = useState(totalRaise);
  
  
    const { handleApprove, approveLoading, approveError, approveData, approveSuccess } = useTokenApproval(pool?.saleContract);
    const { handlePurchase, purchaseLoading, purchaseError, purchaseData,purchaseSuccess } = useTokenInteraction(pool?.saleContract);
  
  
    const { balance, isLoading: isLoadingBalance, error: balanceError } = useUSDTBalance(address);
  
    const { handleClaim,claimLoading,claimError,writeLoading,writeError, } = useTokenClaim(pool?.claimContract);
  
  
    const currentTimestamp = Math.floor(Date.now() / 1000);
  
   const getPercent =(tokenA, tokenB) => {
  if(tokenA=="NaN") return 0;
      return (tokenA / tokenB) * 100;
    }
  
    useEffect(() => {
      if (!isLoadingBalance && !balanceError) {
        setMybalance(Number(etherConverter.toEther(balance)));
      }
    }, [address]);
  
  
  
    useEffect(() => {
      if (!isLoadingAllowance && !allowanceError) {
        //console.log(allowance);
      setMyallowance(Number(etherConverter.toEther(allowance)));
      }
    }, [address, allowance]);
  
    useEffect(() => {
      if (!isLoadinggetUserContribution && !errorgetUserContribution) {
        setMyDeposited(Number(getUserContribution));
      }
    }, [address, getUserContribution]);
  
  
  
  
  
  
  
    useEffect(() => {
      if(pool && !pool?.saleContract) return;
      const multiCallConfig = {
      web3,
      multicallAddress: "0x1Ee38d535d541c55C9dae27B12edf090C608E6Fb",
      interval: 10000
       };
       defaultWatcher.stop();
      
       defaultWatcher.recreate(
      [
        {
       target: pool?.saleContract,
       call: ['totalRaise()(uint256)'],
       returns: [['totalRaise', (val) => val.toString()]]
        }
      ],
      multiCallConfig
       );
      defaultWatcher.subscribe((update) => {
      setCap(etherConverter.toEther(update.value));
      });
       defaultWatcher.start();
        }, [web3]);
      
  
        const handleApproveClick = async () => {
          if (amount > 0) {
           await handleApprove(amount);
          }
        };
        
  
    const handlePurchaseClick = async () => {
      if (amount > 0) {
        await handlePurchase(amount);
      }
    };
  
  
  
    const onChangeHandler = async (amount) => {
      setAmount(amount);
  
  if(amount >0 && amount <= myallowance){
  setApproved(false);
  }else{
  setApproved(true);
  }
  }
  
  
  
  
  
      useEffect(() => {
        if (approveLoading) {
        //  console.log(approveLoading,'approveLoading');
        setBuyLoading(true);
        }
    
        if (approveError) {
         // console.log(approveError,'approveError');
        setBuyLoading(false);
        }
  
        if (approveData) {
         // console.log(approveData,'approveData');
          
          }
    
    
          if (approveSuccess) {
           // console.log(approveSuccess,'approveSuccess');
            setApproved(false);
            setBuyLoading(false);
            // You can handle loading state, e.g., show a spinner for the purchase
          }
  
  
    
        if (purchaseLoading) {
        //  console.log(purchaseLoading,'purchaseLoading');
        setBuyLoading(true);
        }
  
        if (purchaseError) {
        setBuyLoading(false);
        }
  
        if (purchaseData) {
         // console.log(purchaseData,'purchaseData');
          
          }
    
    
          if (purchaseSuccess) {
          //  console.log(purchaseSuccess,'purchaseSuccess');
            setApproved(true);
            setBuyLoading(false);
            // You can handle loading state, e.g., show a spinner for the purchase
          }
  
  
      }, [approveLoading, approveError,approveData,approveSuccess, purchaseLoading,purchaseError,purchaseData,purchaseSuccess, handlePurchase, handleApprove]);
    
  
  
  
  
  */
  
  
  
  
  
  
  

  return (
    <>
        <Navbar/>
        <div className='container my-4 mb-5'>
            <div className='row mb-5'>
                <div className='col-lg-5 my-3'>
                    <div className='sol-wallet p-4' style={{position: 'sticky', top: '110px'}}>
                      <div className='d-flex'>
                          <img  src={img10} className='rounded-circle' height={75} width={75}/>
                          <div className='text-white ms-3'>
                              <h3 className='font-family'>CORESTARTER</h3>
                              <h6 className='font-family text-white-50'>CSTR / BUSD</h6>
                              <span className='badge rounded-pill bg-secondary'>Register</span>
                              <span className='badge rounded-pill bg-success ms-2'>Levels</span>
                              <span className='badge rounded-pill bg-warning text-dark ms-2'>KYC</span>
                          </div>
                      </div>
                      {/* <div className='p-2 mt-3 sol-wallet-detail' style={{background: ''}}> 
                        <div className='text-center '>
                            <button className='px-4 buy-tpad-btn btn btn-primary text-white'>
                            <img src='https://trustpad.io/_next/static/media/pancakeswap.b5559135.svg' width={25} /> <span className='ms-2'> Buy TPAD</span></button>
                        </div>
                      </div> */}
                      {/* <div className='px-3 py-1  text-white mt-3 sol-wallet-detail'>
                          <div className='my-2 d-flex align-items-center justify-content-between'>
                                <h6 className='p-0 m-0'>WhiteList</h6>
                                <h6 className='p-0 m-0 d-flex align-items-center'>None-WhiteListed 
                                    <i className='material-icons text-danger ms-2'>close</i>
                                </h6>
                          </div>
                      </div> */}
                        
                        {/* <div className='px-3 py-2 sol-wallet-address'>
                            <div className='text-white-50 text-center'>
                                <span className=''>KYC: <b>In Review</b>
                                    <div class="spinner-border ms-2 spinner-border-sm" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </span>
                            </div>
                        </div> */}

                        <div className='px-3 py-1   text-white my-4 sol-wallet-detail'>
                          <div className='my-2 d-flex align-items-center justify-content-between'>
                            <h6>Register as</h6>
                            <h5>CLUB<sub>[3x]</sub> </h5>
                          </div>
                          <div className='my-2 d-flex align-items-center justify-content-between'>
                            <h6>Allocated</h6>
                            <h5 className='theme-color'>TBA</h5>
                          </div>
                          <div className='my-2 d-flex align-items-center justify-content-between'>
                            <h6>SOL Wallet</h6>
                            <h5><i className='material-icons border border-secondary p-1 rounded-3 theme-color'>edit</i></h5>
                          </div>
                      </div>
                      <div className='p-3 sol-wallet-address'>
                          <h6 className='text-white-50'>Your SOL wallet address for distribution</h6>
                          <div style={{overflow: 'hidden'}} className='p-2 px-3 wallet-address d-flex align-items-center'>
                            <div className='w-75'>
                              <h6 className='p-0 m-0 flex-wrap'>0xbe4Af1c7435cCE141Cc40AE26..ee69</h6>
                            </div>
                            <div className='w-25 text-end'>
                                <i className='material-icons text-success ms-3 cr-pointer'>done</i>
                            </div>
                          </div>
                          <p className='text-secondary mt-2'>We recommend using the Phantom (dekstop-only) or TrustWallet (mobile) wallets.</p>
                          <div class="mb-3 form-check">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                            <label class="form-check-label text-white" for="exampleCheck1">I confirm providing a valid SOL address. I nderstand that giving a wrong wallet can result in funds loss,</label>
                          </div>
                          <div className='text-center'>
                            <button className='px-4 btn btn-primary buy-tpad-btn rounded-3 text-white'>submit</button>
                          </div>
                      </div>
                    <div className='text-end mb-3'>
                        <a href='#' className='text-white-50'>How To participate</a>
                    </div>

                        {/* buy SNE cards */}

                    <div className=''>
                            <div className='w-75 p-2 rounded-3'>
                                <p className='text-secondary'>Purchased: <span className='text-white'>6,666.65 SNE ($10)</span></p>
                            </div>
                            <div className='border p-2 border-secondary rounded-3 d-flex align-items-center justify-content-between'>
                                <div>
                                    <p className='text-secondary p-0 m-0 d-block'>From</p>
                                    <small className='text-white'>10</small>
                                </div>
                                <div>
                                    <p className='text-secondary p-0 m-0 d-block'>Max. 15 Balance: 14.636.85</p>
                                    <small className='text-white text-end'>BUSD</small>
                                </div>
                            </div>  
                            <div className='text-center mt-3'>
                                <span className=' material-icons text-white'>arrow_downward</span>
                            </div>
                            <div className='border p-2 my-3 border-secondary rounded-3 d-flex align-items-center justify-content-between'>
                                <div>
                                    <p className='text-secondary p-0 m-0 d-block'>To</p>
                                    <small className='text-white'>6666.66</small>
                                </div>
                                <div>
                                    <p className='text-secondary p-0 m-0 d-block'>Max. 6666.5</p>
                                    <small className='text-white text-end'>SNE</small>
                                </div>
                            </div>  
                            <div className='text-center'>
                                <button className='px-4 btn btn-primary buy-tpad-btn rounded-3 text-white'>Buy 6666.66 $SNE</button>
                            </div>
                         </div>
                    

                            <div className=' my-4'>
                                <h5 className=' theme-color'>1 BUSD = 8.333 PLAYZAP</h5>
                                <h6 className=' theme-color'>1 PLAYZAP = 0.12 BUSD</h6>
                            </div>
                            <div className='d-flex align-items-center mt-4'>
                                <div className='w-75'>
                                    <h6 className='text-white'>registration closes in 9 hours, 43 minutes</h6>
                                </div>
                                <div className='w-25 text-end'>
                                    <h6 className='theme-color '>80%</h6>
                                </div>
                            </div>
                            <div class="progress mt-1 bg-secondary mb-2" style={{height: '5px'}}>
                                <div class="progress-bar bg-white" role="progressbar" style={{width : '80%'}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                
                            <div className='w-100 text-end mb-4'>
                                <h6 className='text-white p-0 m-0'>Listing Time</h6>
                                <h6 className='text-warning p-0 m-0'>0 / 2 249 921 PLAYZAP</h6>
                            </div>
                    
                        <div>
                            <p className=' text-white d-flex align-items-center'>
                                <i className='material-icons'>wifi</i>
                                <span className='ms-2'> IDO and distribution on BNB Chain</span>
                            </p>
                        </div>
                        <div>
                            <div className='text-white d-flex my-2 align-items-center'>
                                <h6 className='p-0 m-0 w-25'>📝 Register	</h6>
                                <p className='p-0 m-0 ms-5 w-75'>	Apr 14, 14:00 – Apr 16, 11:00 UTC</p>
                            </div>
                            <div className='text-white d-flex my-2 align-items-center'>
                                <h6 className='p-0 m-0 w-25'>💰 Sale	</h6>
                                <p className='p-0 m-0 ms-5 w-75'>	Apr 16, 14:00 – Apr 17, 14:00 UTC</p>
                            </div>
                            <div className='text-white d-flex my-2 align-items-center'>
                                <h6 className='p-0 m-0 w-25'>🏃‍♂ FCFS</h6>
                                <p className='p-0 m-0 ms-5 w-75'>	Apr 17, 13:00 UTC</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-lg-7 my-3'>
                    <div className='single-pool-detail p-3'>
                        <div className='d-flex flex-wrap align-items-center justify-content-between'>
                            <div className='d-flex align-items-center my-2'>
                                <img  src={img10} className='rounded-circle' height={75} width={75}/>
                                <h3 className='ms-3 text-white font-family'>CORESTARTER</h3>
                            </div>
                            <div className='d-flex align-items-center my-2'>
                                <img  src={partner} className='' width={150}/>
                            </div>
                        </div>
                        <p className='my-3 text-white-50'>The ZapVerse is a powerful ecosystem built and powered by proven solutions and infrastructure, providing a satisfying core experience to our users. Users will have a wide array of in-house and partnered games, played by thousands of people globally. Those low-skill-floor high-skill-ceiling games will provide value corresponsive to the player's skill level. With a truly free-to-play economy, the monetization aspect will come directly from the players interacting actively with each other, playing games, earning prizes and exchanging assets.</p>
                        <div className='my-4'>
                            <h5 className='theme-color font-family'>Pool Details</h5>
                            <div className='d-flex align-items-center w-100 mt-4'>
                                <div className='text-white w-50 d-flex align-items-center'>
                                    <b>Access Type:</b>
                                    <p className='p-0 m-0 ms-3'>Levels</p>
                                </div>
                                <div className='text-white w-50 d-flex align-items-center'>
                                    <b>Hard Cap:</b>
                                    <p className='p-0 m-0 ms-3'>$270 001</p>
                                </div>
                            </div>
                            <div className='text-white mt-4'>
                                <div className='d-flex align-items-center my-2'>
                                    <b>Swap Rate:</b>
                                    <p className='theme-color p-0 m-0 ms-3'>1 PLAYZAP = $0.12 | 8.333 PLAYZAP per 1 BUSD</p>
                                </div>
                                <div className='d-flex align-items-center my-2'>
                                    <b>Start/end:</b>
                                    <p className='p-0 m-0 ms-3'>16 Apr, 14:00 pm –17 Apr, 14:00 pm UTC</p>
                                </div>
                                <div className='d-flex align-items-center my-2'>
                                    <b>FCFS Opens:</b>
                                    <p className='p-0 m-0 ms-3'>Apr 17, 13:20 UTC</p>
                                </div>
                                <div className='d-flex align-items-center my-2'>
                                    <b>Base Allocation:</b>
                                    <p className='theme-color p-0 m-0 ms-3'>1x <span className='text-white'>=</span> $13.64</p>
                                </div>
                            </div>
                        </div>
                        <div className='my-4 mt-5'>
                            <h5 className='theme-color font-family'>Price</h5>
                            <div className='text-white '>
                                <div className='d-flex align-items-center my-2'>
                                    <b>Listing:</b>
                                    <p className='theme-color p-0 m-0 ms-3'>$0.12 (+0.00%)</p>
                                </div>
                                <div className='d-flex align-items-center my-2'>
                                    <b>IDO:</b>
                                    <p className='theme-color p-0 m-0 ms-3'>$0.12</p>
                                </div>
                            </div>
                        </div>
                        <div className='my-4 mt-5'>
                            <h5 className='theme-color font-family'>Token</h5>
                            <div className='text-white '>
                                <div className='d-flex align-items-center my-2'>
                                    <b>Token:</b>
                                    <p className='theme-color p-0 m-0 ms-3'>PlayZap (PLAYZAP) <small className='text-white'> on BNB Chain</small></p>
                                </div>
                                <div className='d-flex align-items-center my-2'>
                                    <b>Total Supply:</b>
                                    <p className='text-white p-0 m-0 ms-3'>150 000 000 PLAYZAP</p>
                                </div>
                                <div className='d-flex align-items-center my-2'>
                                    <b>Initial Supply:</b>
                                    <p className='test-white p-0 m-0 ms-3'>3 749 851 PLAYZAP, <span className='text-white'>market cap </span>$450 000</p>
                                </div>
                                <div className='d-flex align-items-center my-2'>
                                    <b>Token Listing:</b>
                                    <p className='text-white p-0 m-0 ms-3'>TBA</p>
                                </div>
                            </div>
                        </div>
                        <div className='my-4 mt-5'>
                            <h5 className='theme-color font-family'>Distribution</h5>
                            <div className='text-white '>
                                <div className='d-flex align-items-center my-2'>
                                    <b>Distribution:</b>
                                    <p className='text-white p-0 m-0 ms-3'>Claimed on TrustPad</p>
                                </div>
                                <div className='d-flex align-items-center my-2'>
                                    <b>Vesting:</b>
                                    <p className='text-white p-0 m-0 ms-3'>20% at listing, 1 month cliff, then 6 months linear vesting</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        <Footer/>
    </>
  )
}

export default PoolDetail
