import React from 'react';

import img1 from '../../assets/img/img13.jpg';
import img2 from '../../assets/img/img10.jpg';
import img3 from '../../assets/img/img9.jpg';
import img4 from '../../assets/img/img4.jpg';
import img5 from '../../assets/img/img5.jpg';
import img6 from '../../assets/img/img7.jpg';
import img7 from '../../assets/img/img3.jpg';
import img8 from '../../assets/img/img4.jpg';
import img9 from '../../assets/img/img11.jpg';
import img10 from '../../assets/img/img11.jpg';

import part1 from '../../assets/img/part1.png';
import part2 from '../../assets/img/part3.png';
import part3 from '../../assets/img/part4.png';
import part4 from '../../assets/img/part6.png';
import rocket2 from '../../assets/img/rocket2.png';

import tir1 from '../../assets/img/tir1.png';
import tir2 from '../../assets/img/tir2.png';
import tir3 from '../../assets/img/tir3.png';
import tir4 from '../../assets/img/tir4.png';

import Navbar from '../global-components/Navbar';
import Hero_Banner from '../section-components/Hero_Banner';
import Cards from '../section-components/Cards';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Shadow_Card from '../section-components/Shadow_Card';
import Small_Cards from '../section-components/Small_Cards';
import RoadMap from '../section-components/RoadMap';
import Footer from '../global-components/Footer';
import Tires from '../section-components/Tires';
import Tiers2Card from '../section-components/Tiers2Card';

const Home = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4.5,
    slidesToScroll: 1,
    vertical: false,
    verticalSwiping: false,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
  };
  return (
    <>
       <Navbar/>
      <Hero_Banner/>

       {/*
      <div className='container my-5'>
       <div id='slider' className='slider'>
          <Slider {...settings}>
            <Small_Cards img={img1} name='NUMERIC'/>
            <Small_Cards img={img2} name='AHSAN'/>
            <Small_Cards img={img3} name='SYED BASIT'/>
            <Small_Cards img={img4} name='SYED NASIR'/>
            <Small_Cards img={img5} name='SYED MAAZ'/>
          </Slider>
        </div>
          <div className='shadow-round rounded-circle' style={{top: '1150px', right: '50px'}}></div>
*/}


{/*
        <div className='row ' style={{marginTop: '150px'}}>
          <div className='mb-5'>
            <figcaption figcaption class="blockquote-footer text-white mb-0 pb-0">
                LET'S FLY WITH US
            </figcaption>
            <h2 className='theme-color font-family p-0 m-0'><span className='text-warning'> UPCOMING</span> PROJECTS <span className='begde bg-dark rounded-pill px-4 fs-4 py-1'>6</span> </h2>
          </div>
          <div className='col-lg-4 my-3'>
              <Cards img={img1}/>
          </div>
        
        </div>

      </div>

*/}

        {/* partnsers */}

        <div className='partners-bg py-5' id='partners' style={{marginTop: '150px'}}>
          <div className='container'>
              <div className='d-flex align-items-center'>
                <div>
                  <figcaption figcaption class="blockquote-footer text-white mb-0 pb-0">
                      OUR PARTMNERS
                  </figcaption>
                  <h2 className='theme-color font-family p-0 m-0'>PARTNERS</h2>
                </div>
              </div>
              <Slider {...settings} className='mt-5'>
                <img src={part2} className='mx-5' width={'20'} height={'100'} />
                <img src={part3} className='mx-5' width={'20'} height={'100'} />
                <img src={part4} className='mx-5' width={'20'} height={'100'} />
                <img src={part2} className='mx-5' width={'20'} height={'100'} />
                <img src={part1} className='mx-5' width={'20'} height={'100'} />

              </Slider>
          </div>
          <div className='shadow-round rounded-circle' style={{top: '2000px', left: '50px'}}></div>
        </div>

        <div className='container' id='roadmap' style={{marginTop: '150px'}}>
            <RoadMap/>
        </div>

{/*
        <div className='container' style={{marginTop: '150px'}}>
          <figcaption figcaption class="blockquote-footer text-white mb-0 pb-0">
                POOLS
          </figcaption>
          <h1 className='theme-color font-family'>Pools</h1>
          <div className='row'>
            <div className='col-md-4 my-3'>
                <Tires/>
            </div>
            
          </div>
        </div>
*/}
        {/* multi box shadow section */}
        <div className='container'  style={{marginTop: '150px'}} id="tiers">
            <div className='text-center mb-5'>
                <figcaption figcaption class="blockquote-footer text-white mb-0 pb-0">
                      CHOOSE
                </figcaption>
              <h3 className='theme-color font-family my-3'>Tier System</h3>
            </div>
            <div className='row mt-5'>
              <div className='col-lg-3 my-3'>  
                  <Tiers2Card
        count="1"
        img={tir1}
        title="Starter"
        staking="100,000+ $FLUX"
        allocation="Basic Entry Ticket"
        weight="01"
      />
              </div>  
              <div className='col-lg-3 my-3'>
                  <Tiers2Card
        count="2"
        img={tir2}
        title="Advanced"
        staking="250,000+ $FLUX"
        allocation="Early Access to IDOs"
        weight="02"
      />
              </div>  
              <div className='col-lg-3 my-3'>
                  <Tiers2Card
        count="3"
        img={tir3}
        title="Premium"
        staking="500,000+ $FLUX"
        allocation="Guaranteed Allocations"
        weight="03"
      />
              </div>  
              <div className='col-lg-3 my-3'>
                  <Tiers2Card
        count="4"
        img={tir4}
        title="Elite"
        staking="1,000,000+ $FLUX"
        allocation="Multiple Allocations & VIP Access"
        weight="04"
      />
              </div>  
            </div>
        </div>

        <div className='shadow-round rounded-circle' style={{top: '3000px', right: '50px'}}></div>

        <div className='container' style={{marginTop: '150px'}}>
          <div className='row'>
            <div className='col-lg-9 my-3'>
                <h1 className='font-family text-white'>Launch Your Project with <span className='theme-color'>FluxPad</span> </h1>
                <p className='text-secondary'>Ready to elevate your project? At FluxPad, we specialize in taking your IDO to the next level, connecting you with investors and raising the capital you need. Let us help you achieve your funding goals and make a significant impact in the blockchain space.</p>
                <button className="btn btn-primary rounded-pill px-5 mt-3 btn-lg" type="submit">Apply Now</button>
            </div>
            <div className='col-lg-3 my-3 text-center'>
                <img src={rocket2} width={'300'}/>
            </div>
          </div>
        </div> 
    
          {/* thes is shadow */}
          <div className='shadow-round rounded-circle' style={{top: '3500', right: '50px'}}></div>
          <div className='shadow-round rounded-circle' style={{top: '4000px', left: '50px'}}></div>

        <Footer/>
    </>
  );
}

export default Home;
